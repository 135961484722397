.c-carousel {
  max-width: none;
  padding: 0 1rem !important;

  @include bp(large) {
    padding: 0 calc((100vw - 1160px + 2rem) / 2) !important;
  }

  &__navigation {
    position: relative;
    height: calc(var(--swiper-navigation-size) * 2);

    @include bp-down(medium) {
      display: none;
    }
  }

  &__slide {
    border-radius: 1rem;
    overflow: hidden;
    width: 85% !important;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__body {
    padding: 2rem;

    @include bp(large) {
      padding: 3rem;
      width: calc(60% - 80px);
    }
  }

  img {
    object-position: right;
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

.c-carousel--small {
  .c-carousel__slide {
    max-width: 400px;
  }
}

.c-carousel--large {
  .c-carousel__slide {
    max-width: 400px;

    @include bp(large) {
      max-width: 800px;
      display: flex;
    }

    &:not(:last-child) {
      @include bp(medium) {
        margin-right: 4rem;
      }

      @include bp(large) {
        margin-right: 6rem;
      }
    }
  }
}

.c-masthead {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  padding: 0 !important;
}
