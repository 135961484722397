.c-scroll {
  &__section {
    position: relative;
    margin-bottom: 10vh;

    @include bp(small) {
      margin-bottom: 25vh;
      max-height: 700px;
      height: 90vh;
    }
  }

  &__body {
    transition: background-color 0.5s ease-out;

    &::after {
      content: '';
      clear: both;
      display: table;
    }

    @media (min-width: 650px) {
      display: flex;

      &-item {
        flex: 1;
        z-index: 1000;
      }
    }
  }

  &__copy {
    transition: opacity 0.5s ease-out;
    max-width: 414px;
    margin-bottom: 10vh;

    @include bp(650px) {
      margin-top: 40%;
      margin-bottom: 0;
    }
  }

  &__img {
    position: relative;
    transform: scale(0.9);
    transition: all 0.5s ease-out;
    max-width: 375px;
    width: 70%;

    @include bp-down(large) {
      margin: 0 auto;
    }

    &:after {
      content: '';
      display: block;
      background: aquamarine;
      border-radius: 13px;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.5s ease-out;
    }

    &-item {
      border-radius: 13px;
      display: block;
    }
  }
}

.has-scroll-init {
  .c-scroll__copy {
    opacity: 0;
  }

  .c-scroll__section.is-inview {
    .c-scroll__copy {
      opacity: 1;
    }

    .c-scroll__img {
      transform: scale(1);

      &:after {
        transform: rotate(-4deg) scale(1.005);
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
