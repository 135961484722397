.page-template {
  &-slideshow {
    .c-columns {
      color: white;
      background: transparent !important;
      height: auto;
      padding-bottom: 20vh;
    }
  }

  .wp-block-pullquote {
    max-width: 768px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
}
