.c-logo {
  padding: 0.1rem 0;
  height: 30px;

  svg {
    max-width: 150px;
  }
}

.c-logo-icon {
  width: 50px;

  &__bg {
    fill: #0b72ce;
    animation: bg 5s ease-in-out infinite;
    animation-fill-mode: both;
    transform-origin: center;
  }

  &__bar {
    fill: #ffffff;
  }

  &__dot {
    fill: #3baba1;
  }

  &__letter {
    fill: #ffffff;
  }
}

@keyframes bg {
  0% {
    fill: #0b72ce;
    transform: scale(1);
  }

  20% {
    fill: #0b72ce;
    transform: scale(1);
  }

  21% {
    transform: scale(0);
  }

  25% {
    fill: #c361e9;
    transform: scale(1);
  }

  45% {
    fill: #c361e9;
    transform: scale(1);
  }

  46% {
    transform: scale(0);
  }

  50% {
    fill: #3baba1;
    transform: scale(1);
  }

  70% {
    fill: #3baba1;
    transform: scale(1);
  }

  71% {
    transform: scale(0);
  }

  75% {
    fill: #ffbf00;
    transform: scale(1);
  }

  95% {
    fill: #ffbf00;
    transform: scale(1);
  }

  96% {
    transform: scale(0);
  }
}
