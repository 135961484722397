.c-stack {
  &__pagination {
    width: calc(100% - 80px);
    margin: 0 auto;
    max-width: 400px;

    @include bp(medium) {
      max-width: none;
      width: calc(100% - 160px);
      margin-bottom: 20px;
    }

    @include bp(large) {
      z-index: 999;
      position: absolute;
      left: 80px;
      top: 0;
    }
  }

  &__slide {
    @include bp(medium) {
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
    }
  }

  &__img-wrapper {
    @include bp(medium) {
      width: 50%;
    }
  }

  &__img {
    margin: 0 auto;
    transition: $global-transition;
    position: relative;
    padding: 40px;
    transform: rotate(-5deg);
    opacity: 0.5;

    @include bp-down(medium) {
      max-width: 400px;
    }
  }

  &__img-obj {
    border-radius: 1rem;
  }

  &__bg {
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 40px;
    right: 40px;
    background: inherit;
    transition: $global-transition;
    border-radius: 1rem;
    opacity: 0.25;
    z-index: -1;
  }

  &__body {
    flex: 1;

    @include bp(medium) {
      width: 50%;
    }

    > * {
      margin-left: auto;
      margin-right: auto;
      padding: 0 40px;

      @include bp-down(medium) {
        max-width: 400px;
      }

      @include bp(medium) {
        padding: 0 40px 0 80px;
      }

      @include bp(large) {
        padding: 0 80px;
      }
    }
  }

  img {
    max-height: 500px;
    object-fit: cover;
    position: relative;
    width: 100%;
    z-index: 1;
  }
}

.c-stack__slide.swiper-slide-active {
  .c-stack__img {
    transition-delay: 0.1s;
    transform: rotate(0);
    opacity: 1;
  }

  .c-stack__bg {
    transform: rotate(5deg);
    transition-delay: 0.2s;
    opacity: 0.5;
  }
}
