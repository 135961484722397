@import 'swiper/swiper';
@import 'swiper/components/navigation/navigation';
@import 'swiper/components/effect-fade/effect-fade';

:root {
  --swiper-navigation-size: 22px;
  --swiper-navigation-color: $color-link;
}

.swiper-button-prev,
.swiper-button-next {
  top: 0;
  margin-top: 0;
}

.swiper-pagination-bullet-active {
  background-color: $color-link;
}
