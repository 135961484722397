.c-page {
  &__content {
    > *,
    &-item {
      width: 100%;
      max-width: calc(650px - 2rem);
      padding-right: 1rem;
      padding-left: 1rem;
      margin-right: auto;
      margin-left: auto;
    }

    > *:nth-child(1),
    > *:nth-child(2) {
      @include bp(large) {
        max-width: 905px;
      }
    }

    > *:nth-child(2) {
      @extend .u-font--upper;
      font-size: 2.25rem;
      line-height: 1.15;

      @include bp(large) {
        font-size: 4.375em;
        line-height: 1;
      }
    }

    > *:nth-child(3) {
      @extend .u-opacity--half;
      @extend .u-font--3;
      padding: 0 1rem;
      margin-right: auto;
      margin-left: auto;
      text-transform: initial;
    }

    > .wp-block-group {
      max-width: 1160px;
    }

    .wp-block-image {
      text-align: center;

      img {
        border-radius: 1rem;
      }
    }
  }
}
