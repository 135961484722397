/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  position: relative;
  font-size: 2em;
  line-height: 1.25;
  font-weight: 700;
  padding: 0 1rem;

  @include bp(large) {
    font-size: 3.125em;
  }

  &:before {
    background-image: url('../assets/images/quote.svg');
    background-size: contain;
    transform: translate(0, -75%);
    position: absolute;
    width: 85px;
    height: 70px;
    z-index: 0;
    content: '';

    @include bp(large) {
      width: 109px;
      height: 90px;
      transform: translate(-80%, -3rem);
    }
  }

  &:after {
    background-image: url('../assets/images/quote.svg');
    background-size: contain;
    position: absolute;
    width: 85px;
    height: 70px;
    z-index: 0;
    transform: rotate(180deg) translate(-1rem, -75%);
    bottom: 0;
    left: 0;
    content: '';

    @include bp(large) {
      width: 109px;
      height: 90px;
      transform: rotate(180deg) translate(68%, -48%);
    }
  }
}

blockquote p {
  position: relative;
  z-index: 1;
}

blockquote cite {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: normal;
  display: inline-block;
  position: relative;
  z-index: 1;
}

blockquote *:last-child {
  margin-bottom: 0;
}
