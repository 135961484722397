.o-embed--video {
  overflow: hidden;
  position: relative;

  .wp-embed-aspect-16-9 & {
    padding-top: 56.25%;
  }

  .wp-embed-aspect-4-3 & {
    padding-top: 75%;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
