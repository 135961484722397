.c-card {
  border-radius: 1rem;
  overflow: hidden;
  color: $color-white;
  padding: 0;

  @include bp(medium) {
    display: flex;
  }

  &__img img {
    @include bp(medium) {
      display: flex;
      object-fit: cover;
      height: 100%;
    }
  }

  &__title {
    text-decoration: none;
    font-size: 1.5rem;
    line-height: 1.15;
  }

  &__body {
    padding: 2rem;
    max-width: 550px;

    @include bp(medium) {
      max-width: 375px;
    }
  }

  &.wp-block-group {
    padding: 2rem;
  }

  a {
    color: inherit;
  }
}

.c-card--portrait,
.c-card--stack-gallery {
  display: block;
}

.c-card--arrow {
  position: relative;
  top: 5px;
}

.c-card--more {
  text-align: center;
  background: rgba(3, 4, 22, 0.2);
  height: 85vw;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-card--list-layout {
  @include bp(medium) {
    .o-grid__item:nth-child(7n + 1) {
      .c-card {
        display: flex;
        font-size: 1.25rem;
      }

      .c-card__body,
      .c-card__img {
        width: 50%;
      }

      .c-card__body {
        max-width: none;
        padding: 4rem;
      }

      .c-card__title {
        font-size: 2.5rem;
      }
    }
  }

  .c-card__body {
    padding: 2rem;
  }

  img {
    width: 100%;
  }
}

.c-card--corners {
  border-radius: 1rem;
  overflow: hidden;
}
