.c-accordion {
  padding: 0;
  margin: 0;

  &__item {
    list-style: none;
    width: 100%;
    margin: 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-medium-grey;
    }
  }

  &__label {
    cursor: pointer;
    display: block;
    font-weight: bold;
    padding-right: 3em;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='butt' stroke-linejoin='arcs'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E")
      no-repeat top right;
  }

  &__title {
    margin: 2em 0;
  }

  &__content {
    display: none;
    margin-bottom: 3em;
  }

  &__radio {
    display: none;
  }
}

.c-accordion .c-accordion__item:first-of-type .c-accordion__title {
  margin-top: 0;
}

/* Show content boxes when the radio buttons are checked */
.c-accordion__radio:checked {
  + .c-accordion__label {
    color: $colors-3-a;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233baba1' stroke-width='2' stroke-linecap='butt' stroke-linejoin='arcs'%3E%3Cpath d='M18 15l-6-6-6 6'/%3E%3C/svg%3E")
      no-repeat top right;
  }

  + .c-accordion__label + .c-accordion__content {
    display: block;
  }
}
