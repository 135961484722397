.u-margin {
  &-auto {
    margin: 0 auto;
  }

  &-none {
    margin: 0;
  }

  &-top {
    margin-top: 1rem;

    &--none {
      margin-top: 0;
    }

    &--small {
      margin-top: 0.5rem;
    }

    &--large {
      margin-top: 2rem;
    }

    &--huge {
      margin-top: 4rem;
    }

    &--10vh {
      margin-top: 10vh;
    }
  }

  &-right {
    margin-right: 1rem;

    &--none {
      margin-right: 0;
    }

    &--small {
      margin-right: 0.5rem;
    }

    &--large {
      margin-right: 2rem;
    }
  }

  &-bottom {
    margin-bottom: 1rem;

    &--none {
      margin-bottom: 0;
    }

    &--small {
      margin-bottom: 0.5rem;
    }

    &--large {
      margin-bottom: 2rem;
    }

    &--huge {
      margin-bottom: 4rem;
    }

    &--super {
      margin-bottom: 8rem;
    }
  }

  &-left {
    margin-left: 1rem;

    &--none {
      margin-left: 0;
    }

    &--small {
      margin-left: 0.5rem;
    }

    &--large {
      margin-left: 2rem;
    }
  }
}
