.c-nav {
  list-style: none;
  margin: 0;

  &__item:not(:last-child) {
    margin-right: 1.5rem;

    @include bp(small) {
      margin-right: 2rem;
    }
  }

  &__link,
  &__sublink {
    text-decoration: none;
    position: relative;

    &:before {
      content: '';
      background-color: $color-link;
      position: absolute;
      display: block;
      height: 1px;
      bottom: -0.25rem;
      width: 0;
      left: 0;
      transition: $global-transition;
    }

    &:hover:before {
      width: 100%;
    }
  }
}

.c-nav--main {
  background: $colors-3-a;
  border-radius: 1rem;
  padding: 2em;
  display: none;
  flex-direction: column;

  @include bp-down(small) {
    line-height: 2em;
  }

  @include bp(small) {
    flex-direction: row;
    padding: 0;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .c-nav__link,
  .c-nav__sublink {
    color: $color-white;
    text-decoration: none;

    &::before {
      background-color: $color-white;
      opacity: 0.75;
    }

    &:active,
    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  @include bp(small) {
    .c-nav__item.menu-item-has-children {
      position: relative;
    }

    .c-nav__item.menu-item-has-children > .c-nav__link {
      position: relative;

      &:after {
        content: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='white' stroke-width='1.25' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        margin-left: 0.4em;
        transition: $global-transition;
        display: inline-block;
      }
    }

    .c-nav__item.menu-item-has-children.dropdown-active > .c-nav__link::after {
      transform: scale(-1);
    }
  }

  .c-nav__item.c-btn--primary {
    background-color: $color-white;
  }

  .c-nav__item.c-btn--primary .c-nav__link {
    color: $color-blue-black;
  }
}

.c-nav__dropdown {
  list-style-type: none;

  @include bp(small) {
    display: none;
    position: absolute;
    top: 100%;
    width: 100vw;
    margin-left: 0;
    max-width: 375px;
    background-color: $colors-3-a;
    z-index: 1;
    border-radius: 1rem;
    padding: 2rem;
    transform: translate(-50%);

    .c-nav__subitem {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    .dropdown-active & {
      display: block;
      opacity: 1;
      animation: fadeUp 0.3s ease forwards;

      > .c-nav__link {
        font-weight: 800;
      }
    }
  }
}

.c-nav-btn {
  display: block;
  height: 24px;
  width: 24px;
  background: url("data:image/svg+xml,%3Csvg width='20' height='14' viewBox='0 0 20 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7H19' stroke='white' stroke-width='1.25' stroke-linecap='round'/%3E%3Cpath d='M1 1H19' stroke='white' stroke-width='1.25' stroke-linecap='round'/%3E%3Cpath d='M1 13H19' stroke='white' stroke-width='1.25' stroke-linecap='round'/%3E%3C/svg%3E ")
    transparent no-repeat center;
  background-size: contain;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  right: 2em;
  top: 2.5em;

  @include bp(small) {
    display: none;
  }

  &.menu-active + nav > .c-nav--main {
    display: flex;
    opacity: 1;
    animation: fadeUp 0.3s ease forwards;
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeUpPullLeft {
  from {
    opacity: 0;
    transform: translate(-150px, 1rem);
  }

  to {
    opacity: 1;
    transform: translate(-150px, 0);
  }
}
