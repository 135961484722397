.u-padding {
  &-auto {
    padding: 0 auto;
  }

  &-none {
    padding: 0;
  }

  &-top {
    padding-top: 1rem;

    &--small {
      padding-top: 0.5rem;
    }

    &--large {
      padding-top: 2rem;
    }

    &--huge {
      padding-top: 4rem;
    }
  }

  &-right {
    padding-right: 1rem;

    &--small {
      padding-right: 0.5rem;
    }

    &--large {
      padding-right: 2rem;
    }
  }

  &-bottom {
    padding-bottom: 1rem;

    &--small {
      padding-bottom: 0.5rem;
    }

    &--large {
      padding-bottom: 2rem;
    }

    &--huge {
      padding-bottom: 4rem;
    }
  }

  &-left {
    padding-left: 1rem;

    &--small {
      padding-left: 0.5rem;
    }

    &--large {
      padding-left: 2rem;
    }
  }
}
