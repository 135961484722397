.c-dingbat {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 1rem;

  &:before {
    content: '';
    display: inline-block;
    height: 50px;
    width: 50px;
    background-image: url('../assets/images/dingbats-small.png');
    margin-right: 1rem;
  }

  &--white-1:before {
    background-position-y: 290px;
    animation: shake 1.35s ease-in-out infinite;
    animation-fill-mode: both;
    transform-origin: 25px 22px;
  }

  &--white-2:before {
    background-position-y: 350px;
    animation: spin 0.5s linear infinite;
    animation-fill-mode: both;
    transform-origin: 24px 35px;
  }

  &--white-3:before {
    background-position-y: 400px;
  }

  &--white-4:before {
    background-position-y: 500px;
    animation: spin 1s linear infinite;
    animation-fill-mode: both;
    transform-origin: 28px 32px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  15% {
    transform: rotate(25deg);
  }

  30% {
    transform: rotate(-25deg);
  }

  45% {
    transform: rotate(35deg);
  }

  60% {
    transform: rotate(0deg);
  }
}
