/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  color: $color-bg;
  background-color: $color-white;
  font-size: 0.875rem;
  padding: 4em 0 6em;

  @include bp(medium) {
    width: 100%;
    padding: 3em 0;
  }

  &__brand-icon {
    @include bp-down(medium) {
      grid-column: 1 / -1;
    }
  }

  &__widget {
    grid-column: 1 / -1;

    @include bp-down(medium) {
      margin-top: 2rem;
    }
  }

  &__legals {
    grid-column: 1 / -1;

    @include bp-down(medium) {
      margin-top: 2rem;
    }
  }

  &__legals-menu,
  &__copyright {
    @include bp(medium) {
      display: inline-block;
    }
  }
}
