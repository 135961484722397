.o-grid {
  display: grid;
  padding-bottom: 3rem !important;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @include bp(large) {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
  }
}

.o-grid--2 {
  grid-template-columns: 1fr 1fr;
}

.o-grid--3 {
  @include bp(small) {
    grid-template-columns: 1fr 1fr;
  }

  @include bp(large) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.o-grid--index {
  .o-grid__item:nth-child(7n + 1) {
    grid-column: 1 / -1;
  }
}
