/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */

.o-layout {
  display: block;

  @include bp(medium) {
    display: flex;
  }
}

.o-layout--mobile {
  display: flex;
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;

  > .o-layout__item {
    flex: none;
  }
}

.o-layout--2 {
  > .o-layout__item {
    @include bp(medium) {
      width: 49%;
    }
  }
}

.o-layout--3 {
  > .o-layout__item {
    @include bp(medium) {
      width: 32%;
    }
  }
}

.o-layout--4 {
  > .o-layout__item {
    @include bp(medium) {
      width: 24%;
    }
  }
}

.o-layout-grid {
  display: block;

  @include bp(medium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
  }
}

.o-layout-grid--3 {
  @include bp(medium) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.o-layout-grid--4 {
  @include bp(medium) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.o-layout-grid--mobile {
  @include bp-down(medium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    grid-row-gap: 2rem;
  }
}
