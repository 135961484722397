/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  padding-bottom: 5em;
  margin-bottom: 0;
  max-width: 650px;

  &__img {
    display: block;
    width: 100%;
    border-radius: 0.5rem;
  }

  &__author a,
  &__content a {
    color: inherit;
  }

  &__more-reading {
    margin-top: -1px;
  }
}
