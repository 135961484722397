/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  width: 100%;
  padding: 2.5rem 0;
  position: sticky;
  z-index: 9999;
  top: 0;
  transition: background-color 500ms ease-in-out;
  backdrop-filter: blur(8px);
}
