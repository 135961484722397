.c-lightbox {
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  position: fixed;
  z-index: 999999;
  background: rgba(0, 0, 0, 0);
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.c-lightbox__close {
  display: none;
  position: fixed;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  transition: $global-transition;

  &:hover {
    cursor: pointer;
    background: $color-link;
    padding: 3px;
  }
}

.c-lightbox__body {
  display: flex;
  border-radius: $global-radius;
  margin-top: calc(5vh + 30px);
  margin-bottom: 5vh;
  background: $color-bg;
  justify-content: center;
  opacity: 0;
}

.c-lightbox__form {
  padding: 2rem 1rem;

  @include bp(960px) {
    padding: 3rem;
  }
}

.c-lightbox__img {
  border-radius: $global-radius 0 0 $global-radius;
  overflow: hidden;
  display: none;
  flex: 1;

  @include bp(960px) {
    display: block;
  }
}

.c-lightbox__img-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

// State modifiers

.c-lightbox--active {
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  transition: background 0.5s ease-in-out;

  .c-lightbox__body {
    transition: margin-top 0.4s ease-in, opacity 0.6s ease-in;
    transition-delay: 0.7s;
    margin-top: 5vh;
    opacity: 1;
  }

  .c-lightbox__close {
    display: block;
  }
}
