.u-background {
  &--curator {
    background-image: url('../assets/images/curator-c.svg');
    background-size: 125%;
    background-position: 60% 90%;
    background-repeat: no-repeat;

    @media (pointer: coarse) and (orientation: portrait) {
      background-size: 160vw;
      background-position-x: 80%;
      background-position-y: 95%;
    }

    .c-columns {
      background: transparent !important;
    }
  }

  &--default {
    background: $color-bg;
  }
}
