.c-sticky {
  // transition: 0.75s all ease-in-out;

  @include bp(medium) {
    // position: relative;
    // transform: translate(-25vh, -50%) rotate(-5deg);
    // opacity: 0;
    // position: fixed;
    // top: 50%;
    // left: 75%;
  }

  // &:after {
  //   content: '';
  //   background-color: #00D1FF;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   position: absolute;
  //   z-index: -1;
  //   border-radius: 1rem;
  //   transition: 1s all ease-in-out;
  //   border-radius: 1rem;
  //   opacity: 0.25;
  // }

  video {
    height: 65vh;
    max-height: 700px;
    border-radius: 1rem;
    line-height: 0;
    display: block;
    margin: 0 auto;

    @include bp(medium) {
      height: 75vh;
    }
  }
}

.observe-active .c-sticky {
  @include bp(medium) {
    // transition-delay: 0.5s;
    // transform: translate(-25vh, -50%) rotate(0);
    // opacity: 1;

    // &:after {
    //   transform: rotate(4deg);
    //   transition-delay: 0.6s;
    //   opacity: 0.7;
    // }
  }
}
