@keyframes bump {
  0% {
    transform: scale(1, 0.995);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.995);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 0.995);
  }
}

@keyframes beat {
  0% {
    transform: translateY(5px) scale(0.999999) rotate(1deg);
  }

  25% {
    transform: translateY(0) scale(1) rotate(0);
  }

  50% {
    transform: translateY(8px) scale(0.98) rotate(1deg);
  }

  75% {
    transform: translateY(0) scale(1) rotate(0);
  }

  100% {
    transform: translateY(3px) scale(0.99) rotate(1deg);
  }
}
