/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: 1160px;
  padding: 0 1rem;
}

.o-wrapper-parent {
  background: $color-blue-black;

  > * {
    @extend .o-wrapper;
    margin-bottom: 10vh;
  }
}
