.c-sparkle {
  position: relative;

  &__feature {
    position: relative;
  }

  &__item {
    animation: beat 1s ease-in-out 1s infinite alternate;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    &--above {
      z-index: 1;
    }
  }
}

.c-sparkle-sprite {
  background-image: url('../assets/images/sparkles-sprite.png');
  margin-top: 20px;
  transition: opacity 0.4s ease-out, margin-top 0.2s ease-out;
  transition-delay: 0.5s;
  position: absolute;
  opacity: 0;
  display: none;

  @include bp(medium) {
    display: block;
  }

  &--1 {
    height: 60px;
    width: 67px;
  }

  &--2 {
    width: 67px;
    height: 110px;
    background-position-y: 110px;
  }

  &--3 {
    width: 67px;
    height: 148px;
    background-position-y: 258px;
  }

  &--4 {
    width: 67px;
    height: 111px;
    background-position-y: 369px;
  }

  &--5 {
    width: 67px;
    height: 56px;
    background-position-y: 424px;
  }

  &--6 {
    width: 53px;
    height: 71px;
    background-position-y: 495px;
  }

  &--7 {
    width: 99px;
    height: 40px;
    background-position-y: 535px;
  }

  &--8 {
    width: 183px;
    height: 136px;
    background-position-y: 671px;
  }

  &--9 {
    width: 62px;
    height: 58px;
    background-position-y: 728px;
  }

  &--10 {
    width: 106px;
    height: 35px;
    background-position-y: 763px;
  }

  &--11 {
    width: 76px;
    height: 211px;
    background-position-y: 973px;
  }

  &--12 {
    width: 46px;
    height: 25px;
    background-position-y: 998px;
  }

  &--13 {
    width: 230px;
    height: 155px;
    background-position-y: 1152px;
  }

  &--14 {
    width: 66px;
    height: 110px;
    background-position-y: 1262px;
  }

  &--15 {
    width: 135px;
    height: 52px;
    background-position-y: 1316px;
  }

  &--16 {
    width: 92px;
    height: 100px;
    background-position-y: 1416px;
  }

  &.is-inview {
    opacity: 1 !important;
    margin-top: 0 !important;
  }
}

.c-bump {
  animation: bump 1s ease-in-out 1s infinite alternate;
  transform-origin: bottom;
}
