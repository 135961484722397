.u-pos {
  &--rel {
    position: relative;
  }

  &--under {
    z-index: -1;
  }

  &--over {
    z-index: 1;
  }
}
