.u-font {
  &--reg {
    font-weight: normal;
  }

  &--bold {
    font-weight: bold;
  }

  &--upper {
    text-transform: uppercase;
  }

  &--lower {
    text-transform: initial;
  }

  &--1 {
    font-size: 2.25rem;
    line-height: 1.15;
  }

  &--2 {
    font-size: 1.75rem;
    line-height: 1.15;
  }

  &--3 {
    font-size: 1.5rem;
    line-height: 1.15;
  }

  &--4 {
    font-size: 1.25rem;
    line-height: 1.15;
  }

  &--5 {
    font-size: 1.125rem;
    line-height: 1.15;
  }

  &--6 {
    font-size: 1rem;
    line-height: 1.15;
  }

  &--p {
    @extend .u-font--reg;
    text-transform: initial;
    font-size: 0.8125rem; /* 13px */

    @include bp(medium) {
      font-size: 0.875em; /* 14px */
    }

    @include bp(large) {
      font-size: 0.9375em; /* 15px */
    }

    @include bp(full) {
      font-size: 1em; /* 16px */
    }
  }
}

.u-font--super {
  @extend .u-font--1;
  @extend .u-font--upper;

  @include bp(large) {
    font-size: 4.375em;
    line-height: 1;
  }
}
