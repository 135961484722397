.u-size {
  &-56em {
    max-width: 56em;
  }

  &-40em {
    max-width: 40em;
  }

  &-25em {
    max-width: 25em;
  }

  &-max {
    max-width: 1180px;
  }

  &-maxwidth {
    max-width: none;

    .wp-block-group__inner-container {
      @extend .o-wrapper;
    }
  }

  &-winheight {
    min-height: 100vh;
  }

  &-supertitle {
    @include bp(large) {
      width: 75%;
    }
  }
}
