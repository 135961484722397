.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.wp-block-pullquote p {
  font-size: inherit !important;
  line-height: inherit !important;
}

.wp-block-video {
  margin: 0 !important;

  video {
    display: block;
  }
}
