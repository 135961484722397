.c-anim {
  &--twist {
    opacity: 0;
    transition: all 0.75s ease-out;
    transform: rotate(3deg) translateY(75px);

    &.is-inview {
      opacity: 1;
      transform: rotate(0) translateY(0);
    }
  }

  &--float {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transform: translateY(30px) scale(0.98);

    &.is-inview {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
}
