.c-form {
  max-width: calc(650px - 2rem) !important;

  label,
  legend,
  &__label,
  &__legend {
    margin: 0.5em 1em;
    display: block;
    color: $color-light-grey;
  }

  legend,
  &__legend {
    transform: translateY(-0.5rem);
    padding-top: 0;
  }

  select,
  textarea,
  input:not([type='radio']):not([type='checkbox']),
  &__input:not([type='radio']):not([type='checkbox']) {
    margin-bottom: 1rem !important;
    width: 100%;
    transition: $global-transition;
    outline: none;
    appearance: none;
    border: 2px solid $colors-3-a;
    color: $color-white;
    background: $color-bg;
    padding: 1rem;
    border-radius: 1rem;

    &:focus,
    &:active {
      color: $color-bg;
      background: $color-white;
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    margin-right: 1rem;
  }

  input[type='submit'],
  &__input--submit {
    background-color: $color-link !important;
    width: auto !important;
  }

  fieldset {
    max-width: none !important;
  }

  // Hubspot specific overrides
  .hs-input:not([type='radio']):not([type='checkbox']) {
    width: 100% !important;
  }

  .hs-form-field .inputs-list {
    list-style-type: none;
    margin-left: 0;
  }

  .no-list {
    margin: 0 0 2rem;
    list-style-type: none;

    .hs-error-msg {
      padding-left: 0;
      color: $colors-4-d;
      display: flex;

      &:before {
        content: '\0021\0020';
        font-size: 1.25em;
        font-weight: bold;
        background: $colors-4-d;
        border-radius: 1rem;
        display: inline-block;
        text-align: center;
        color: $color-white;
        margin-right: 0.5em;
        height: 1.85rem;
        padding: 0 0.75rem;
      }
    }
  }
}
